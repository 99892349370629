<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { instance } from '@/helpers/interceptors'
import { logoff } from '@/helpers/logoff'
import type { AxiosResponse } from 'axios'
import { useAppInfoStore } from '@/stores/appInfo'
import type { PatientForm, Passenger } from '@/modules/patientForm'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { Form, Field } from 'vee-validate'
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue'
import ModalComponent from '@/components/UI/ModalComponent.vue'
import Collapsible from '@/components/UI/Collapsible.vue'
import FormStepper from '@/components/UI/FormStepper.vue'
import PopoverComponent from '@/components/UI/PopoverComponent.vue'
import ContactPhoneValidateModal from '@/components/Functions/ContactPhoneValidateModal.vue'
import ContactAddressValidateModal from '@/components/Functions/ContactAddressValidateModal.vue'
import dayjs from 'dayjs'

/* ----- enum ----- */
enum SubmitType {
  Auto = 0,
  Manual = 1
}

enum TPInvolvedType {
  MyFault = 0,
  TPFault = 1,
  NotCarAccident = 2
}

/* ----- refs ----- */
const isTPInvolvedRef = ref()
const hasFPAttorneyRef = ref()
const hasPassengersRef = ref()
const patientFormRef = ref<typeof Form>()

/* ----- Data ----- */
const appInfoStore = useAppInfoStore()
const showPhoneModal = ref(false)
const router = useRouter()
const transitionEntered = ref(false)
const showForm = ref(true)
const showSpinner = ref(false)

const responseModal = reactive({
  visible: false,
  success: true,
  result: ''
})

const showStepAlert = ref(false)

const totalSteps: number = 5
const currentStep = ref(1)

const form = reactive({
  accountNo: null,
  submitType: SubmitType.Auto,
  portalSourceTypeID: 0,
  patientPortalInfoID: 0,
  caseID: 0,
  firstName: null,
  lastName: null,
  homeTel: null,
  mobileTel: null,
  email: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  fpInsCompany: null,
  fpInsAddress: null,
  fpInsCity: null,
  fpInsState: null,
  fpInsZip: null,
  fpPolicyNumber: null,
  fpInsClaimNumber: null,
  fpInsFirstName: null,
  fpInsLastName: null,
  fpInsPhone: null,
  fpInsFax: null,
  fpAttyFirm: null,
  fpAttyFirstName: null,
  fpAttyLastName: null,
  fpAttyAddress: null,
  fpAttyCity: null,
  fpAttyState: null,
  fpAttyZip: null,
  fpAttyPhone: null,
  fpAttyFax: null,
  tpDefFirstName: null,
  tpDefLastName: null,
  tpInsCompany: null,
  tpInsAddress: null,
  tpInsCity: null,
  tpInsState: null,
  tpInsZip: null,
  tpPolicyNumber: null,
  tpInsClaimNumber: null,
  tpInsFirstName: null,
  tpInsLastName: null,
  tpInsPhone: null,
  tpInsFax: null,
  isTPInvolved: null,
  isAccident: undefined,
  accidentDesc: null,
  accidentLoc: null,
  isWorkRelated: undefined,
  hasFPAtty: undefined,
  additionalPassengers: [{ firstName: '' }, { firstName: '' }] as Passenger[],
  additionalInformation: undefined
} as PatientForm)

const isStepForward = ref(false)
const hasPassengers = ref(false)

const confirmPhoneValidation = ref(false)
const confirmAddressValidation = ref(false)

const getDefaultValidationResponse = () => ({
  addressResponse: {},
  phoneResponse: '',
  phoneCheck: true,
  phoneCheckMobile: true
})

const validationResponse = ref(getDefaultValidationResponse())

/* ----- Computed ----- */
const zip = computed({
  get(): string | null {
    return form.zip
  },
  set(newValue: string | null) {
    form.zip = newValue ? newValue.replace('-', '') : null
  }
})

const contactValidationForm = computed({
  get() {
    const contactForm = {
      company: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      mobile: ''
    }

    switch (currentStep.value) {
      case 1:
        Object.assign(contactForm, {
          company: 'Resident',
          address: form.address,
          city: form.city,
          state: form.state,
          zip: form.zip,
          mobile: form.mobileTel
        })
        break
      case 2:
        Object.assign(contactForm, {
          company: 'Insurance',
          phone: form.fpInsPhone
        })
        break
      case 3:
        Object.assign(contactForm, {
          company: 'Attorney',
          phone: form.fpAttyPhone
        })
        break
      case 5:
        Object.assign(contactForm, {
          company: "Other Person's Insurance",
          phone: form.tpInsPhone
        })
        break
    }
    return contactForm
  },
  set(newValue: any) {
    switch (currentStep.value) {
      case 1:
        form.address = newValue.vAddress2
        form.city = newValue.vCity
        form.state = newValue.vState
        form.zip = newValue.vZip5 + newValue.vZip4
        break
    }
  }
})

/* ----- Watch ----- */
watch(currentStep, (newValue, oldValue) => {
  isStepForward.value = newValue > oldValue

  // clean up validation info
  confirmPhoneValidation.value = false
  confirmAddressValidation.value = false
  validationResponse.value = getDefaultValidationResponse()
})

// when form.isTPInvolved changes, render Collapsible
watch([() => form.isTPInvolved], ([newIsTPInvolved]) => {
  if (transitionEntered.value && isTPInvolvedRef.value) {
    isTPInvolvedRef.value.toggleCollapse(newIsTPInvolved !== TPInvolvedType.TPFault)
  }
})

// when form.hasFPAtty changes, render Collapsible
watch([() => form.hasFPAtty], ([newHasFPAtty]) => {
  if (transitionEntered.value && hasFPAttorneyRef.value) {
    hasFPAttorneyRef.value.toggleCollapse(!newHasFPAtty)
  }
})

// when form.hasFPAtty changes, render Collapsible
watch([hasPassengers], ([newHasPassengers]) => {
  if (transitionEntered.value && hasPassengersRef.value) {
    hasPassengersRef.value.toggleCollapse(!newHasPassengers)
  }
})

// display delayed alert message
watch(transitionEntered, () => {
  if (currentStep.value === 3) delayedAlert()

  // validate current step
  patientFormRef.value?.validate()
})

// on submit success, redirect to login
watch(showForm, (newShowForm) => {
  if (!newShowForm)
    setTimeout(() => {
      // use replace prevent user going back to patient form
      router.replace('/')
    }, 3000)
})

/* ----- Methods ----- */
const extractNumber = (input: string) => {
  return input.replace(/\D/g, '')
}

// For beforeunload eventhandler
const onAutoSave = async () => {
  await postPaitentFormRequest(SubmitType.Auto)
}

/**
 * Submit the form, validate front end first, and then post to backend
 */
const onSubmit = async () => {
  showSpinner.value = true
  const isValid = await validateParty()
  if (!isValid) {
    showSpinner.value = false
    return
  }

  await postPaitentFormRequest(SubmitType.Manual)
  showSpinner.value = false
}

const validateParty = () => {
  return instance()
    .post(`/Patient/ValidateParty`, contactValidationForm.value)
    .then((response) => {
      const data = response.data
      if (data.errors) {
        alert(data.errors)
        return false
      }

      // skip addressResponse so the address modal won't show at the same time.
      const { addressResponse, ...phoneInfo } = data
      validationResponse.value = Object.assign(getDefaultValidationResponse(), phoneInfo)

      // validate phone
      if (validationResponse.value.phoneResponse && !confirmPhoneValidation.value) {
        return false
      }

      // validate address (automatic or explicit)
      validationResponse.value = Object.assign(getDefaultValidationResponse(), data)
      const _addressResponse: any = validationResponse.value.addressResponse
      if (
        (contactValidationForm.value.address == _addressResponse.vAddress2 &&
          contactValidationForm.value.city == _addressResponse.vCity &&
          contactValidationForm.value.state == _addressResponse.vState &&
          contactValidationForm.value.zip == _addressResponse.vZip5 + _addressResponse.vZip4) ||
        Object.keys(_addressResponse).length == 0 ||
        confirmAddressValidation.value
      ) {
        validationResponse.value.addressResponse = {}
      } else {
        return false
      }
      return true
    })
}

const nextStepHandler = async () => {
  // validate address
  if (currentStep.value == totalSteps) {
    onSubmit()
  } else {
    const result = await validateParty()
    if (result) currentStep.value = Math.min(currentStep.value + 1, totalSteps)
  }
}

const phoneValidationHandler = () => {
  confirmPhoneValidation.value = true
  nextStepHandler()
}

const addressValidationHandler = (_addressResponsePicked: string) => {
  confirmAddressValidation.value = true
  const addressResponse: any = validationResponse.value.addressResponse
  if (addressResponse.vAddress2 && _addressResponsePicked == 'Corrected') {
    // save party (computed setter)
    contactValidationForm.value = validationResponse.value.addressResponse
  }
  nextStepHandler()
}

/**
 * Helper method, post the form to API backend
 */
const postPaitentFormRequest = async (submitType: SubmitType) => {
  await patientFormRef.value?.validate().then(async (obj: any) => {
    if (obj.valid) {
      form.submitType = submitType

      // remove empty passenger if id = 0
      form.additionalPassengers = form.additionalPassengers.filter(
        (p) => !(p.id === 0 && !p.firstName && !p.lastName && !p.relationToPatient && JSON.stringify(p.dos) === 'null' && JSON.stringify(p.dob) === 'null' && !p.mobileTel)
      )

      // post to api
      await instance()
        .post(`/Patient/SubmitPatientInfo`, form)
        .then((response: AxiosResponse) => {
          if (response.data.error == undefined) {
            if (submitType == SubmitType.Manual) {
              showForm.value = false
              Object.assign(responseModal, { visible: false, success: true, result: response.data })
            }
            logoff(appInfoStore)
          } else {
            // only display error if user manually submit the form
            Object.assign(responseModal, { visible: true, success: false, result: response.data.error })
          }
        })
        .catch(() => {
          const defaultErrorMessage = 'Sorry, we are experiencing technical difficulties. Please contact our service team at 855-995-3345.'
          Object.assign(responseModal, { visible: true, success: false, result: defaultErrorMessage })
        })
    }
  })
}

const hasPassengersHandler = () => {
  if (!form.additionalPassengers.length) {
    addPassenger()
  }
}

const getPatientPortalInfo = () => {
  return instance()
    .get(`/Patient/GetPatientPortalInfo/${appInfoStore.appInfo.caseId}`)
    .then((response: AxiosResponse) => {
      if (response) {
        Object.assign(form, response.data)

        // add additional attribute
        form.additionalPassengers = form.additionalPassengers.map((p) => ({ ...p, isDeleted: false }))

        // set if has passengers
        hasPassengers.value = form.additionalPassengers.length > 0
      }
    })
}

const delayedAlert = () => {
  setTimeout(() => {
    showStepAlert.value = true
  }, 2000)
}

const addPassenger = () => {
  form.additionalPassengers.push({
    id: 0,
    caseID: form.caseID,
    firstName: '',
    lastName: '',
    relationToPatient: '',
    dos: null,
    dob: null,
    mobileTel: '',
    sourceID: 2,
    isDeleted: false
  })
}

const deletePassengerHandler = async (index: number) => {
  const passenger = form.additionalPassengers[index]
  showSpinner.value = true

  if (passenger.id == 0) {
    form.additionalPassengers.splice(index, 1)
  } else {
    await instance()
      .post(`/Patient/DeletePassenger`, passenger)
      .then((response: AxiosResponse) => {
        if (response.data.error == undefined) {
          //form.additionalPassengers.splice(index, 1)
          form.additionalPassengers[index].isDeleted = true
        } else {
          // only display error if user manually submit the form
          Object.assign(responseModal, { visible: true, success: false, result: response.data.error })
        }
      })
      .catch(() => {
        const defaultErrorMessage = 'Sorry, we are experiencing technical difficulties. Please contact our service team at 855-995-3345.'
        Object.assign(responseModal, { visible: true, success: false, result: defaultErrorMessage })
      })
  }
  showSpinner.value = false
}

onMounted(() => {
  // auto save on rfresh and tab close
  window.addEventListener('beforeunload', onAutoSave)

  switch (appInfoStore.appInfo.caseId) {
    case -1:
      // login without authorization
      form.accountNo = appInfoStore.appInfo.accountNo
      break
    case 0:
      // not logged in
      router.push('/')
      break
    default:
      // get previous information from the table (if the patient is authenticated)
      getPatientPortalInfo().then(() => {
        // authorized, assign store source to local source
        form.caseID = appInfoStore.appInfo.caseId
        form.portalSourceTypeID = appInfoStore.appInfo.portalSourceTypeID
        form.accountNo = appInfoStore.appInfo.accountNo
      })
  }
})

onBeforeRouteLeave((to, from, next) => {
  // auto save on route change
  onAutoSave()
  next()
})

onUnmounted(() => {
  window.removeEventListener('beforeunload', onAutoSave)
})

defineExpose({ isTPInvolvedRef, hasFPAttorneyRef, patientFormRef, hasPassengersRef })
</script>

<template>
  <!-- Check JWT authorization -->
  <ContactPhoneValidateModal
    v-model="showPhoneModal"
    :id="'validatePhoneModal'"
    :confirmPhoneValidation="confirmPhoneValidation"
    :validationResponse="validationResponse"
    :selected-party="contactValidationForm"
    @submit="phoneValidationHandler"
  ></ContactPhoneValidateModal>
  <ContactAddressValidateModal
    :id="'validateAddressModal'"
    :validationResponse="validationResponse"
    :selected-party="contactValidationForm"
    @submit="addressValidationHandler"
  ></ContactAddressValidateModal>
  <Form v-slot="{ errors, isSubmitting }" v-if="showForm" ref="patientFormRef">
    <!-- Add Spinner -->
    <LoadingSpinner :visible="isSubmitting || showSpinner" />
    <Transition :name="isStepForward ? 'step-forward' : 'step-backward'" mode="out-in" @enter="transitionEntered = true" @leave="transitionEntered = false">
      <FormStepper
        v-if="currentStep === 1"
        :previous-step="1"
        v-model:current-step="currentStep"
        :next-step="2"
        :total-steps="totalSteps"
        :section-header="'Patient Information'"
        :disable-next="Object.keys(errors).length > 0"
        @next-step-click="nextStepHandler"
      >
        <div class="row">
          <!-- Begin First Party Info -->
          <div class="col-md-6 row mb-3">
            <label for="firstName" class="col-md-4 col-form-label">Patient First Name</label>
            <div class="col-md-8">
              <Field name="Patient First Name" rules="required|max:50" v-model="form.firstName" v-slot="{ field, errors }">
                <input id="firstName" type="text" :class="['form-control', errors.length ? 'border-danger' : '']" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="lastName" class="col-md-4 col-form-label">Patient Last Name</label>
            <div class="col-md-8">
              <Field name="Patient Last Name" rules="required|max:50" v-slot="{ field, errors }" v-model="form.lastName">
                <input id="lastName" type="text" :class="['form-control', errors.length ? 'border-danger' : '']" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="mobileTel" class="col-md-4 col-form-label">Cell Phone</label>
            <div class="col-md-8">
              <Field name="Patient Cellphone" rules="digits:10" v-slot="{ field, errors }" v-model="form.mobileTel">
                <input
                  id="mobileTel"
                  v-mask="'(###) ###-####'"
                  type="tel"
                  :class="['form-control', errors.length ? 'border-danger' : '']"
                  @focus=";($event.target as HTMLInputElement).select()"
                  :value="field.value"
                  @input="($event) => (form.mobileTel = extractNumber(($event.target as HTMLInputElement).value))"
                />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="email" class="col-md-4 col-form-label">Email</label>
            <div class="col-md-8">
              <Field name="Email" rules="email|max:300" v-slot="{ field, errors }" v-model="form.email">
                <input id="email" type="text" :class="['form-control', errors.length ? 'border-danger' : '']" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="address" class="col-md-4 col-form-label">Address</label>
            <div class="col-md-8">
              <Field name="Address" rules="required|max:150" v-slot="{ field, errors }" v-model="form.address">
                <input id="address" type="text" :class="['form-control', errors.length ? 'border-danger' : '']" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="city" class="col-md-4 col-form-label">City</label>
            <div class="col-md-8">
              <Field name="Your City" rules="required|max:100" v-slot="{ field, errors }" v-model="form.city">
                <input id="city" type="text" :class="['form-control', errors.length ? 'border-danger' : '']" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="state" class="col-md-4 col-form-label">State</label>
            <div class="col-md-8">
              <Field name="Your State" rules="required|max:2" v-slot="{ field, errors }" v-model="form.state">
                <input id="state" type="text" v-mask="'AA'" :class="['form-control', errors.length ? 'border-danger' : '']" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="zip" class="col-md-4 col-form-label">Zip</label>
            <div class="col-md-8">
              <Field name="Your Zip" rules="required|min:5" v-slot="{ field, errors }" v-model="zip">
                <input id="zip" v-mask="'#####-####'" :class="['form-control', errors.length ? 'border-danger' : '']" v-bind="field" type="tel" />
              </Field>
            </div>
          </div>
        </div>

        <!-- Validation Summary -->
        <div v-if="errors">
          <div v-if="Object.keys(errors).length" style="display: flex; flex-wrap: wrap; justify-content: center" class="mb-2">
            <div v-for="(message, field) in errors" :key="field" class="alert alert-danger p-1 m-2" role="alert">
              {{ message }}
            </div>
          </div>
        </div>
      </FormStepper>

      <FormStepper
        v-else-if="currentStep === 2"
        :previous-step="1"
        v-model:current-step="currentStep"
        :next-step="3"
        :total-steps="totalSteps"
        :section-header="'Your Auto Insurance'"
        :disable-next="Object.keys(errors).length > 0"
        @next-step-click="nextStepHandler"
      >
        <div class="row">
          <div class="col-md-6 row mb-3">
            <label for="fpInsCompany" class="col-md-4">Insurance Company</label>
            <div class="col-md-8">
              <Field name="Insurance Company" rules="max:150" v-slot="{ field, errors }" v-model="form.fpInsCompany">
                <input id="fpInsCompany" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="fpInsPhone" class="col-md-4">Agency Phone Number</label>
            <div class="col-md-8">
              <Field name="Agency Phone Number" rules="digits:10" v-slot="{ field, errors }" v-model="form.fpInsPhone">
                <input
                  id="fpInsPhone"
                  v-mask="'(###) ###-####'"
                  type="tel"
                  :class="['form-control', errors.length ? 'border-danger' : '']"
                  autocomplete="disabled"
                  @focus=";($event.target as HTMLInputElement).select()"
                  :value="field.value"
                  @input="($event) => (form.fpInsPhone = extractNumber(($event.target as HTMLInputElement).value))"
                />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="fpPolicyNumber" class="col-md-4">Policy Number</label>
            <div class="col-md-8">
              <Field name="Policy Number" rules="max:50" v-slot="{ field, errors }" v-model="form.fpPolicyNumber">
                <input id="fpPolicyNumber" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" />
              </Field>
            </div>
          </div>

          <div class="col-md-6 row mb-3">
            <label for="fpInsClaimNumber" class="col-md-4">Claim Number</label>
            <div class="col-md-8">
              <Field name="Your Insurance Claim Number" rules="max:50" v-model="form.fpInsClaimNumber" v-slot="{ field, errors }">
                <input id="fpInsClaimNumber" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" />
              </Field>
            </div>
          </div>
        </div>

        <!-- Validation Summary -->
        <div v-if="errors">
          <div v-if="Object.keys(errors).length" style="display: flex; flex-wrap: wrap; justify-content: center" class="mb-2">
            <div v-for="(message, field) in errors" :key="field" class="alert alert-danger p-1 m-2" role="alert">
              {{ message }}
            </div>
          </div>
        </div>
      </FormStepper>

      <FormStepper
        v-else-if="currentStep === 3"
        :previous-step="2"
        v-model:current-step="currentStep"
        :next-step="4"
        :total-steps="totalSteps"
        :section-header="'Attorney Information'"
        :disable-next="Object.keys(errors).length > 0"
        @next-step-click="nextStepHandler"
      >
        <template #header>
          <Transition name="alert" mode="out-in">
            <div v-if="showStepAlert" class="alert alert-success alert-dismissible mb-2" style="box-shadow: 5px 5px 5px #ccc" role="alert">
              <i class="bi bi-hand-thumbs-up" style="-webkit-text-stroke: 1px"></i>
              <span style="margin-left: 0.5rem">Way to go! You have only 2 steps left.</span>
              <button type="button" class="btn-close" aria-label="Close" @click="showStepAlert = false"></button>
            </div>
            <h6 style="line-height: 55px" v-else>Thank you for using our online services. Please take a moment to provide us the following information.</h6>
          </Transition>
        </template>
        <div class="row mb-2" style="padding-left: 6px">
          <label for="hasFPAtty" class="col-md-3">Are you represented by an attorney?</label>
          <div class="col">
            <label><Field name="Are you represented by an attorney?" v-model="form.hasFPAtty" type="radio" :value="true" /> Yes</label>
            <label class="mx-2"><Field name="Has FP Attorney" v-model="form.hasFPAtty" type="radio" :value="false" /> No</label>
          </div>
        </div>

        <Collapsible ref="hasFPAttorneyRef" header-dir="d-none" :start-collapse="!form.hasFPAtty">
          <template #body>
            <div class="row mb-3">
              <div class="col-md-6 row mb-3">
                <label for="fpAttyFirm" class="col-md-4">Attorney Firm Name</label>
                <div class="col-md-8">
                  <Field name="Attorney Firm Name" rules="max:50" v-model="form.fpAttyFirm" v-slot="{ field, errors }">
                    <input id="fpAttyFirm" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" autocomplete="disabled" />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="fpAttyPhone" class="col-md-4">Attorney Phone Number</label>
                <div class="col-md-8">
                  <Field name="Attorney Phone Number" rules="digits:10" v-slot="{ field, errors }" v-model="form.fpAttyPhone">
                    <input
                      id="fpAttyPhone"
                      v-mask="'(###) ###-####'"
                      type="tel"
                      :class="['form-control', errors.length ? 'border-danger' : '']"
                      autocomplete="disabled"
                      @focus=";($event.target as HTMLInputElement).select()"
                      :value="field.value"
                      @input="($event) => (form.fpAttyPhone = extractNumber(($event.target as HTMLInputElement).value))"
                    />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="fpAttyFirstName" class="col-md-4">Attorney First Name</label>
                <div class="col-md-8">
                  <Field name="Your Attorney's First Name" rules="max:50" v-model="form.fpAttyFirstName" v-slot="{ field, errors }">
                    <input id="fpAttyFirstName" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" autocomplete="disabled" />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="fpAttyLastName" class="col-md-4">Attorney Last Name</label>
                <div class="col-md-8">
                  <Field name="Your Attorney's Last Name" rules="max:50" v-model="form.fpAttyLastName" v-slot="{ field, errors }">
                    <input id="fpAttyLastName" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" autocomplete="disabled" />
                  </Field>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <!-- Validation Summary -->
        <div v-if="errors">
          <div v-if="Object.keys(errors).length" style="display: flex; flex-wrap: wrap; justify-content: center" class="mb-2">
            <div v-for="(message, field) in errors" :key="field" class="alert alert-danger p-1 m-2" role="alert">
              {{ message }}
            </div>
          </div>
        </div>
      </FormStepper>

      <FormStepper
        v-else-if="currentStep === 4"
        :previous-step="3"
        v-model:current-step="currentStep"
        :next-step="5"
        :total-steps="totalSteps"
        :section-header="'Passengers\' Information'"
        :disable-next="Object.keys(errors).length > 0"
      >
        <div class="row mb-2" style="padding-left: 6px">
          <label for="hasFPAtty" class="col-md-3">Were there additional Passengers?</label>
          <div class="col">
            <label><Field name="Were there additional Passengers?" v-model="hasPassengers" type="radio" :value="true" @click="hasPassengersHandler" /> Yes</label>
            <label class="mx-2"><Field name="Were there additional Passengers?" v-model="hasPassengers" type="radio" :value="false" /> No</label>
          </div>
        </div>
        <Collapsible ref="hasPassengersRef" header-dir="d-none" :start-collapse="!hasPassengers">
          <template #body>
            <div class="row mb-2" v-for="(passenger, idx) in form.additionalPassengers" :key="idx" :class="{ 'd-none': passenger.isDeleted }">
              <div class="col-md-12 row mb-3">
                <h5 class="col-5">Passenger {{ idx + 1 }}:</h5>
                <div class="col text-end">
                  <button type="button" class="btn btn-secondary btn-sm" @click="deletePassengerHandler(idx)">
                    <i class="bi bi-trash3"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4 row mb-2">
                <label :for="`Passenger_${idx}_FirstName`" class="col-md-5">First Name</label>
                <div class="col-md-7">
                  <Field :name="`Passenger ${idx + 1} First Name`" rules="max:50" v-slot="{ field, errors }" v-model="passenger.firstName">
                    <input
                      :id="`Passenger_${idx}_FirstName`"
                      type="text"
                      class="form-control"
                      :class="errors.length ? 'border-danger' : ''"
                      v-bind="field"
                      autocomplete="disabled"
                    />
                  </Field>
                </div>
              </div>
              <div class="col-md-4 row mb-3">
                <label :for="`Passenger_${idx}_LastName`" class="col-md-5">Last Name</label>
                <div class="col-md-7">
                  <Field :name="`Passenger ${idx + 1} Last Name`" rules="max:50" v-slot="{ field, errors }" v-model="passenger.lastName">
                    <input
                      :id="`Passenger_${idx}_LastName`"
                      type="text"
                      class="form-control"
                      :class="errors.length ? 'border-danger' : ''"
                      v-bind="field"
                      autocomplete="disabled"
                    />
                  </Field>
                </div>
              </div>
              <div class="col-md-4 row mb-3">
                <label :for="`Passenger_${idx}_Relationship`" class="col-md-5">Relation</label>
                <div class="col-md-7">
                  <Field :name="`Passenger ${idx + 1} Relationship`" rules="max:50" v-slot="{ field, errors }" v-model="passenger.relationToPatient">
                    <input
                      :id="`Passenger_${idx}_Relationship`"
                      type="text"
                      :list="`Passenger_${idx}_Relationship_Options`"
                      class="form-control"
                      :class="errors.length ? 'border-danger' : ''"
                      v-bind="field"
                      placeholder="Type to search..."
                      autocomplete="disabled"
                    />
                    <datalist :id="`Passenger_${idx}_Relationship_Options`">
                      <option value="Parent"></option>
                      <option value="Spouse"></option>
                      <option value="Relative"></option>
                      <option value="Friend"></option>
                      <option value="Child"></option>
                    </datalist>
                  </Field>
                </div>
              </div>

              <div class="col-md-4 row mb-3">
                <label :for="`Passenger_${idx}_DOS`" class="col-md-5">
                  DOS
                  <PopoverComponent>
                    <template #body> Date the individual was seen at the hospital. </template>
                  </PopoverComponent>
                </label>
                <div class="col-md-7">
                  <Field
                    :name="`Passenger ${idx + 1} DOS`"
                    :rules="{ after: ['01/01/1900', true], before: ['today', true] }"
                    v-slot="{ field, errors }"
                    :value="dayjs(passenger.dos, 'YYYY-MM-DD').format('MM/DD/YYYY')"
                    @input="passenger.dos = new Date($event.target.value)"
                  >
                    <input
                      :id="`Passenger_${idx}_DOS`"
                      v-mask="'##/##/####'"
                      placeholder="MM/DD/YYYY"
                      type="tel"
                      class="form-control"
                      :class="errors.length ? 'border-danger' : ''"
                      v-bind="field"
                      autocomplete="disabled"
                    />
                  </Field>
                </div>
              </div>
              <div class="col-md-4 row mb-3">
                <label :for="`Passenger_${idx}_DOB`" class="col-md-5">DOB</label>
                <div class="col-md-7">
                  <Field
                    :name="`Passenger ${idx + 1} DOB`"
                    :rules="{ after: ['01/01/1900', true], before: ['today', true] }"
                    v-slot="{ field, errors }"
                    :value="dayjs(passenger.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')"
                    @input="passenger.dob = new Date($event.target.value)"
                  >
                    <input
                      :id="`Passenger_${idx}_DOB`"
                      v-mask="'##/##/####'"
                      placeholder="MM/DD/YYYY"
                      type="tel"
                      class="form-control"
                      :class="errors.length ? 'border-danger' : ''"
                      v-bind="field"
                      autocomplete="disabled"
                    />
                  </Field>
                </div>
              </div>
              <div class="col-md-4 row mb-3">
                <label :for="`Passenger_${idx}_Phone`" class="col-md-5">Phone</label>
                <div class="col-md-7">
                  <Field :name="`Passenger ${idx + 1} Phone`" rules="digits:10" v-slot="{ field, errors }" v-model="passenger.mobileTel">
                    <input
                      :id="`Passenger_${idx}_Phone`"
                      type="tel"
                      v-mask="'(###) ###-####'"
                      class="form-control"
                      :class="errors.length ? 'border-danger' : ''"
                      @focus=";($event.target as HTMLInputElement).select()"
                      :value="field.value"
                      @input="($event) => (passenger.mobileTel = extractNumber(($event.target as HTMLInputElement).value))"
                      autocomplete="disabled"
                    />
                  </Field>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-sm" @click="addPassenger">Add Passenger</button>
          </template>
        </Collapsible>
        <!-- Validation Summary -->
        <div v-if="errors">
          <div v-if="Object.keys(errors).length" style="display: flex; flex-wrap: wrap; justify-content: center" class="mb-2">
            <div v-for="(message, field) in errors" :key="field" class="alert alert-danger p-1 m-2" role="alert">
              {{ message }}
            </div>
          </div>
        </div>
      </FormStepper>

      <FormStepper
        v-else-if="currentStep === 5"
        :previous-step="4"
        v-model:current-step="currentStep"
        :next-step="5"
        :total-steps="totalSteps"
        :section-header="'Other Person\'s Information'"
        :disable-next="Object.keys(errors).length > 0"
        @save="onSubmit"
      >
        <div class="row mb-2" style="padding-left: 6px">
          <label for="isTPInvolved" class="col-md-2">Who was at fault?</label>
          <div class="col">
            <label style="margin-right: 1rem" class="mb-3"
              ><Field name="Who was at fault?" v-model="form.isTPInvolved" type="radio" :value="TPInvolvedType.MyFault" /> I was at fault</label
            >
            <label style="margin-right: 1rem" class="mb-3"
              ><Field name="Who was at fault?" v-model="form.isTPInvolved" type="radio" :value="TPInvolvedType.TPFault" /> Another driver was at fault</label
            >
            <label class="mb-3"
              ><Field name="Who was at fault?" v-model="form.isTPInvolved" type="radio" :value="TPInvolvedType.NotCarAccident" /> This was not the result of a car accident</label
            >
          </div>
        </div>

        <Collapsible ref="isTPInvolvedRef" header-dir="d-none" :start-collapse="!form.isTPInvolved">
          <template #body>
            <div class="row">
              <div class="col-md-6 row mb-3">
                <label for="tpDefFirstName" class="col-md-5">Other Person's First Name</label>
                <div class="col-md-7">
                  <Field name="Other Person's First Name" rules="max:50" v-slot="{ field, errors }" v-model="form.tpDefFirstName">
                    <input id="tpDefFirstName" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" autocomplete="disabled" />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="tpDefLastName" class="col-md-5">Other Person's Last Name</label>
                <div class="col-md-7">
                  <Field name="Other Driver's Last Name" rules="max:50" v-slot="{ field, errors }" v-model="form.tpDefLastName">
                    <input id="tpDefLastName" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" autocomplete="disabled" />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="tpInsCompany" class="col-md-5">Other Person's Insurance Company</label>
                <div class="col-md-7">
                  <Field name="Other Person's Insurance Company" rules="max:150" v-slot="{ field, errors }" v-model="form.tpInsCompany">
                    <input id="tpInsCompany" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" autocomplete="disabled" />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="tpInsPhone" class="col-md-5">Insurance Phone Number</label>
                <div class="col-md-7">
                  <Field name="Insurance Phone Number" rules="digits:10" v-slot="{ field, errors }" :model-value="form.tpInsPhone">
                    <input
                      id="tpInsPhone"
                      v-mask="'(###) ###-####'"
                      type="tel"
                      :class="['form-control', errors.length ? 'border-danger' : '']"
                      autocomplete="disabled"
                      @focus=";($event.target as HTMLInputElement).select()"
                      :value="field.value"
                      @input="($event) => (form.tpInsPhone = extractNumber(($event.target as HTMLInputElement).value))"
                    />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="tpPolicyNumber" class="col-md-5">Policy Number</label>
                <div class="col-md-7">
                  <Field name="Policy Number" rules="max:50" v-slot="{ field, errors }" v-model="form.tpPolicyNumber">
                    <input id="tpPolicyNumber" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" />
                  </Field>
                </div>
              </div>

              <div class="col-md-6 row mb-3">
                <label for="tpInsClaimNumber" class="col-md-5">Claim Number</label>
                <div class="col-md-7">
                  <Field name="Claim Number" rules="max:50" v-slot="{ field, errors }" v-model="form.tpInsClaimNumber">
                    <input id="tpInsClaimNumber" type="text" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" />
                  </Field>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <div class="mb-2" style="padding-left: 6px">
          <div class="form-floating">
            <textarea class="form-control" v-model="form.additionalInformation" placeholder="Additional Information?" id="floatingTextarea2" style="height: 100px"></textarea>
            <label for="floatingTextarea2" style="">Additional Information?</label>
          </div>
        </div>
        <!-- Validation Summary -->
        <div v-if="errors">
          <div v-if="Object.keys(errors).length" style="display: flex; flex-wrap: wrap; justify-content: center" class="mb-2">
            <div v-for="(message, field) in errors" :key="field" class="alert alert-danger p-1 m-2" role="alert">
              {{ message }}
            </div>
          </div>
        </div>
      </FormStepper>
    </Transition>
  </Form>

  <!-- Success Alert -->
  <div class="row justify-content-center m-2" v-if="!showForm">
    <div class="alert alert-success" role="alert">
      <div>{{ responseModal.result }}</div>
      <div class="mt-2">You will be redirected to login in 3 seconds ...</div>
    </div>
  </div>

  <!-- Result Modal, disable close button if the form is submitted successfully -->
  <ModalComponent v-if="responseModal.visible" v-model="responseModal.visible">
    <template #header> </template>
    <div :class="['alert', `alert-${responseModal.success ? 'success' : 'danger'}`]" role="alert">
      {{ `${responseModal.success ? 'Success: ' : 'Error: '} ${responseModal.result}` }}
    </div>
  </ModalComponent>
</template>

<style scoped>
.step-forward-enter-active {
  transition: all 0.3s ease-out;
  /* transition-delay: 0.3s; */
}
.step-forward-leave-active {
  transition: all 0.3s ease-in;
}

.step-forward-enter-from {
  transform: translate(300px, 0);
  opacity: 0;
}

.step-forward-leave-to {
  transform: translate(-300px, 0);
  opacity: 0;
}

.step-backward-enter-active {
  transition: all 0.3s ease-out;
  /* transition-delay: 0.3s; */
}
.step-backward-leave-active {
  transition: all 0.3s ease-in;
}

.step-backward-enter-from {
  transform: translate(-300px, 0);
  opacity: 0;
}

.step-backward-leave-to {
  transform: translate(300px, 0);
  opacity: 0;
}

.alert-enter-active,
.alert-leave-active {
  transition: all 0.5s ease-out;
}

.alert-enter-from,
.alert-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
