<script setup lang="ts">
import { ref } from 'vue'

defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const showPopover = ref(false)
</script>

<template>
  <div class="popover-container">
    <a href="#" @click="showPopover = !showPopover"><i class="bi bi-info-circle-fill mx-1"></i></a>
    <div class="popover-wrapper" v-if="showPopover">
      <div class="card popover-card">
        <h5 class="card-header" v-if="$slots.header">
          <slot name="header"></slot>
        </h5>
        <div class="card-body">
          <p class="card-text">
            <slot name="body">Test</slot>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.popover-container {
  display: inline-block;
  margin: auto 0.25rem;
  vertical-align: top;
}

.popover-wrapper {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.popover-card {
  border: 1px solid #cccccc;
  position: absolute;
  left: 10px;
  width: 250px;
  box-shadow: 5px 5px 10px#ccc;
}

.popover-card:before {
  position: absolute;
  transform: translate(-100%, 10%);
  top: 0;
  left: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #cccccc;
}
</style>
