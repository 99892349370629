import { createRouter, createWebHistory } from 'vue-router'
import LoginPanel from '../views/LoginPanel.vue'
import PatientForm from '../views/PatientForm.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/token/:id?',
      alias: ['/Home/PatientLogin', '/'],
      name: 'LoginPanel',
      component: LoginPanel
    },
    {
      // form page
      path: '/PatientForm',
      name: 'PatientForm',
      component: PatientForm
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // }
  ]
})

export default router
