import { instance } from '@/helpers/interceptors'
import type { AxiosResponse } from 'axios'

export const login = async (appInfoStore: any, payload: any) => {
  return await instance()
    .post('/App/Authenticate', payload)
    .then(async (apiResponse: AxiosResponse) => {
      if (apiResponse.data.error == undefined) {
        // Store data in Vuex
        appInfoStore.$patch({
          appInfo: {
            ...appInfoStore.appInfo,
            ...apiResponse.data,
            loginWithoutAuthentication: payload.loginWithoutAuthentication
          }
        })
      } else {
        // error handling
        return apiResponse.data.error
      }
    })
}
