//import store from '../../store/store';
import axios from 'axios'
import dayjs from 'dayjs'
import { login } from './login'
import { useXsrfTokenStore } from '@/stores/xsrfToken'
import { useAppInfoStore } from '@/stores/appInfo'

export const instance = () => {
  const xsrfTokenStore = useXsrfTokenStore()
  const appInfoStore = useAppInfoStore()

  const tmpInstance = axios.create({
    baseURL: location.origin + '/api/',
    //timeout: 1000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      // get token from Vuex store after xsrfToken component has been called
      'X-XSRF-TOKEN': xsrfTokenStore.token,
      //Forces browser to not use cache and always get the request. Fixes issue with chrome duplicate tab and the api requests are being cached and not retrieving new data. (Manage case page...)
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    }
  })

  tmpInstance.interceptors.response.use(
    async (response) => {
      // check if the error occurred on login page
      if (response.data.error != undefined && response.data.error == 'Unauthorized' && !['/', '/home/patientlogin'].includes(location.pathname.toLowerCase())) {
        // re-authorize
        await login(appInfoStore, {
          accountNo: appInfoStore.appInfo.caseId,
          loginWithoutAuthentication: appInfoStore.appInfo.loginWithoutAuthentication,
          dob: dayjs(appInfoStore.appInfo.dob).format('MM/DD/YYYY'),
          dos: dayjs(appInfoStore.appInfo.dos).format('MM/DD/YYYY')
        }).then(async () => {
          // on successful authroization, retry current request
          await tmpInstance.request(response.config).then((r) => {
            // overwrite original response
            response = r
          })
        })
      }

      return response
    },
    async (error) => {
      switch (error.response.status) {
        case 400:
          // Overwrite original generic error data
          Object.assign(error.response, { data: 'Request failed with status code 400' })
          break
      }
      return Promise.reject(error)
    }
  )
  return tmpInstance
}
