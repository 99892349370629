<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue'

const emit = defineEmits<{
  (e: 'update:currentStep', step: Number): void
  (e: 'save'): void
  (e: 'nextStepClick'): void
}>()

const props = defineProps({
  previousStep: {
    type: Number,
    default: 1,
    required: true
  },
  currentStep: {
    type: Number,
    default: 1,
    required: true
  },
  nextStep: {
    type: Number,
    default: 1,
    required: true
  },
  totalSteps: {
    type: Number,
    default: 1,
    required: true
  },
  sectionHeader: {
    type: String,
    default: ''
  },
  disableNext: {
    type: Boolean,
    default: false
  }
})

const hasNextStepClickListener = ref(false)
const nextStepHandler = () => {
  // if there is no event listen, default behavior is to move on to the next step
  if (!hasNextStepClickListener.value) emit('update:currentStep', props.nextStep)
  // otherwise, let the parent component handle it.
  emit('nextStepClick')
}

onMounted(() => {
  // "!!" (double negation) used to ensure that a value is treated as a boolean value, even if it's originally not a boolean.
  hasNextStepClickListener.value = !!getCurrentInstance()?.vnode.props?.onNextStepClick // getCurrentInstance() only available inside lifecycle hooks
})
</script>

<template>
  <div class="row justify-content-center stepper-container">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="progress-bar">
      <div class="progress-step" :class="{ 'bg-primary': idx <= currentStep ? true : false }" v-for="idx in [...Array(totalSteps)].map((e, i) => i + 1)" :key="idx"></div>
    </div>
    <div class="section-header">
      <span class="step-icon bg-primary">{{ currentStep }}</span>
      <span style="font-size: 1.25rem">{{ sectionHeader }}</span>
    </div>
    <div class="section-content">
      <slot></slot>
    </div>
    <div class="row footer">
      <slot name="footer">
        <button class="col-md-3 btn text-primary border border-primary border-2" type="button" v-if="currentStep !== 1" @click="emit('update:currentStep', previousStep)">
          Back
        </button>
        <button class="col-md-3 btn btn-primary" :disabled="disableNext" type="button" v-if="currentStep !== totalSteps" @click="nextStepHandler">Next</button>
        <button class="col-md-3 btn btn-primary" :disabled="disableNext" type="button" v-else @click="emit('save')">Save and Submit</button>
      </slot>
    </div>
  </div>
</template>

<style scoped>
.stepper-container {
  background-color: #fff;
  padding: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.25rem;
}

.progress-bar > .progress-step {
  width: 100%;
  height: 0.5rem;
  margin: 0.5rem 0.25rem;
  border-radius: 0.25rem;
  border: none;
}

.section-header {
  margin-top: 1rem;
  font-weight: bold;
}

.section-header > .step-icon {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0.25rem #cde6ff solid;
  color: #fff;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.section-content {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.footer {
  justify-content: space-around;
}

.footer button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
