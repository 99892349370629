<script setup lang="ts">
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue'

const props = defineProps({
  startCollapse: { type: Boolean, default: true },
  showArrow: { type: Boolean, default: true },
  headerDir: { type: String, default: 'left' },
  bodyDir: { type: String, default: 'top' },
  collapsedDir: { type: String, default: 'bottom' },
  uncollapsedDir: { type: String, default: 'top' },

  bodyClass: { type: Object, default: () => ({}) },
  headerClass: { type: Object, default: () => ({}) },
  headerStyle: { type: Object, default: () => ({}) }
})

const emit = defineEmits(['collapsed', 'beforeResize'])

const collapsed = ref(props.startCollapse)

const resizeObserver = reactive(new ResizeObserver(() => resizeHandler()))

watch(
  () => props.startCollapse,
  (newValue) => {
    collapsed.value = newValue
  }
)

const body = ref<HTMLDivElement>()

onMounted(() => {
  collapsed.value = props.startCollapse

  if (!body.value) return
  body.value.style.maxHeight = collapsed.value ? '0' : `${body.value.scrollHeight}px`
  resizeObserver.disconnect()
  resizeObserver.observe(body.value)
})

onUnmounted(() => {
  resizeObserver.disconnect()
})

const toggleCollapse = (collapse: boolean | null = null) => {
  const val = collapse === null ? !collapsed.value : collapse
  if (val !== collapsed.value) {
    collapsed.value = val
    emit('collapsed', val)

    if (body.value) {
      emit('beforeResize')
      body.value.style.maxHeight = val ? '0' : `${body.value.scrollHeight}px`
    }
  }
}
const resizeHandler = () => {
  if (!collapsed.value) {
    window.requestAnimationFrame(() => {
      if (!body.value) return
      body.value.style.maxHeight = `${body.value.scrollHeight + 120}px`
    })
  }
}

defineExpose({ body, toggleCollapse })
</script>

<template>
  <div class="Collapsible" :class="{ [bodyDir]: true }">
    <div v-if="$slots.body && $slots.body()" ref="body" class="body" :class="Object.assign({ collapsed }, bodyClass)">
      <div class="bodyWrapper">
        <slot name="body" :collapsed="collapsed" />
      </div>
    </div>
    <div class="header" :class="Object.assign(headerClass, { [headerDir]: true })" :style="headerStyle" @click="() => toggleCollapse()">
      <slot v-if="$slots.header && $slots.header()" name="header" :class="{ collapsed }" :collapsed="collapsed" />
      <div v-else>View {{ collapsed ? 'More' : 'Less' }}</div>
      <i v-if="showArrow" class="gg-chevron-down" :class="{ [collapsed ? collapsedDir : uncollapsedDir]: true }" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/icons/chevron-down.scss';
.Collapsible {
  $collapse-transition-s: 0.5s;
  display: flex;
  flex-direction: column;
  &.bottom {
    flex-direction: column-reverse;
  }
  .header {
    display: flex;
    cursor: pointer;

    &.right {
      flex-direction: row-reverse;
    }
  }

  .bodyWrapper {
    padding: 6pt;
  }

  .body {
    overflow: hidden;
    box-sizing: border-box;
    transition: all $collapse-transition-s ease-out;

    &.collapsed {
      max-height: 0;
    }
  }

  .gg-chevron-down {
    transition: transform $collapse-transition-s ease-out;
    &.right {
      transform: rotate(-90deg) translateX(-20%);
    }
    &.left {
      transform: rotate(90deg) translateX(-20%);
    }
    &.top {
      transform: rotate(180deg) translateX(-20%) translateY(-10%);
    }
  }
}
</style>

<style lang="scss">
.Collapsible {
  .header {
    > *:not(i) {
      flex-grow: 1;
    }
  }
}
</style>
