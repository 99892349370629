<script lang="ts">
declare global {
  interface Window {
    Cypress: any
  }
}
</script>
<script setup lang="ts">
import { instance } from '@/helpers/interceptors'
import type { AxiosResponse } from 'axios'
import LogRocket from 'logrocket'
import dayjs from 'dayjs'
import { useAppInfoStore } from '@/stores/appInfo'
import { ref, reactive, onMounted, watch, computed } from 'vue'

const Environments = {
  Undefined: 0,
  Development: 1,
  Test: 2,
  Staging: 3,
  Master: 4,
  Production: 5
}

interface envInterface {
  appVersion: string
  aspEnv: number
  pacificTime: dayjs.Dayjs
  maintenanceStartTime: dayjs.Dayjs
  maintenanceEndTime: dayjs.Dayjs
}

const appInfoStore = useAppInfoStore()

const env = reactive({
  appVersion: '',
  aspEnv: 0,
  pacificTime: dayjs(),
  maintenanceStartTime: dayjs().add(1, 'day'),
  maintenanceEndTime: dayjs().subtract(1, 'day')
} as envInterface)

const currentYear = ref(new Date().getFullYear())
const dateFormat = ref('MM/DD/YYYY h:mm a')

const isInProduction = computed(() => {
  return env.aspEnv === Environments.Production
})

watch(appInfoStore.appInfo, (currentValue, oldValue) => {
  console.log('identifyLogRocket')
  identifyLogRocket()
})

const initializeLogRocket = () => {
  //Only run LogRocket if not testing
  if (!window.Cypress) {
    if (window.location.origin === 'https://portal.paratusllc.org') {
      LogRocket.init('pqeggi/paratus-web')
    } else {
      LogRocket.init('pqeggi/paratus-web-test')
    }
  }
}

const identifyLogRocket = () => {
  if (appInfoStore.appInfo.accountNo) {
    LogRocket.identify(appInfoStore.appInfo.accountNo, {
      dateOfBirth: appInfoStore.appInfo.dob instanceof Date ? appInfoStore.appInfo.dob.toDateString() : appInfoStore.appInfo.dob.toString()
    })
  }
}

onMounted(() => {
  initializeLogRocket()

  identifyLogRocket()

  // Get IsFromAzure, AspEnv, AppVersion
  instance()
    .get('/App/GetEnvironment')
    .then((response: AxiosResponse) => {
      if (response) {
        Object.assign(env, response.data, {
          pacificTime: dayjs(response.data.pacificTime),
          maintenanceStartTime: dayjs(response.data.maintenanceStartTime),
          maintenanceEndTime: dayjs(response.data.maintenanceEndTime)
        })
      }
    })
})
</script>

<template>
  <div class="app-body">
    <header>
      <!-- <div class="navbar navbar-light box-shadow py-0" :class="env.aspEnv == 'Development' ? 'bg-secondary' : 'bg-white'"> -->
      <div class="navbar navbar-light bg-white box-shadow py-0">
        <div class="container d-flex justify-content-between">
          <a href="https://www.paratusllc.org" class="navbar-brand d-flex align-items-center py-0">
            <img class="img-fluid" alt="logo" src="@/assets/images/ParatusLogo_56x50.png" style="width: 50px; border-radius: 5px" />
            <span class="company-header-text">paratus</span>
          </a>
          <div class="d-none d-sm-block" id="google_translate_element"></div>
        </div>
      </div>
      <div
        class="alert alert-warning text-center font-weight-bold"
        role="alert"
        v-if="env.maintenanceStartTime.isBefore(env.pacificTime) && env.maintenanceEndTime.isAfter(env.pacificTime)"
      >
        We are under maintenance. You might experience service interruption between
        {{ env.maintenanceStartTime.format(dateFormat) }} PST and {{ env.maintenanceEndTime.format(dateFormat) }} PST
      </div>
    </header>
    <main>
      <!-- Change router view after login and submit -->
      <div class="container">
        <RouterView />
      </div>
    </main>
    <footer class="app-footer text-light text-center">
      <!-- Copyright -->
      <div class="p-2">
        &copy; Paratus Partners {{ `${currentYear} Version (${env.appVersion})` }}&nbsp;
        <div v-if="Object.keys(env).length === 0 && env.constructor === Object">{ empty }</div>
        <div v-if="!isInProduction">{{ env }}</div>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Prevent clicking a href causing LogRocket to prematurely terminate the session by pointing the href target attribute to a iframe -->
    <iframe name="hidden-iframe" style="visibility: hidden" width="0px" height="0px"></iframe>
  </div>
</template>

<style scoped>
.app-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  margin-top: 10px;
  margin-bottom: 10px;
}
.app-footer {
  margin-top: auto;
  background-color: #606060;
}
</style>
