<script setup lang="ts">
import type { AxiosResponse } from 'axios'
import { instance } from '@/helpers/interceptors'
import { login } from '@/helpers/login'
import { ref, reactive, computed, nextTick, onBeforeMount, onMounted, onBeforeUnmount } from 'vue'
import type { TokenResponse, LoginInfo } from '@/modules/loginPanel'
import { useRouter, useRoute } from 'vue-router'
import { useAppInfoStore } from '@/stores/appInfo'
import { useXsrfTokenStore } from '@/stores/xsrfToken'
import { xsrfToken } from '@/helpers/xsrfToken'
import ModalComponent from '@/components/UI/ModalComponent.vue'
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue'
import { Form, Field } from 'vee-validate'

/* ----- data ----- */
const appInfoStore = useAppInfoStore()
const xsrfTokenStore = useXsrfTokenStore()
const route = useRoute()
const router = useRouter()

const showSpinner = ref(false)
const showLoginSpinner = ref(false)
const showLoginPanel = ref(true)
const tokenId = ref(route.params.id)
const initialLoad = ref(true)
const welcomeMessageVisible = ref(true)
const responseResult = ref('')
const patientCount = ref(0)

const tokenResponse = reactive({
  accountNo: '',
  firstName: '',
  chosenFactors: []
} as TokenResponse)

const form = reactive({
  accountNo: '',
  loginWithoutAuthentication: false,
  dob: '',
  dos: ''
} as LoginInfo)

const modalToggle = reactive({
  needHelpModal: false,
  loginModal: false,
  errorModal: false
})

const alphaNumTokens = {
  mask: 'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
  tokens: {
    F: {
      // pattern defines token "F"
      pattern: /[0-9a-zA-Z-]/,
      transform: function (v: string) {
        return v.toLocaleUpperCase()
      }
    }
  }
}

/* ----- computed ----- */
const maskedAccountNo = computed(() => {
  return form.accountNo.slice(0, -4).replace(/./g, '*') + form.accountNo.slice(-4)
})

/* ----- Methods ----- */
const onResize = () => {
  // Set loginModal and welcomeMessage visibility based on screen size
  if (initialLoad.value) modalToggle.loginModal = window.matchMedia('(max-width: 767px)').matches
  welcomeMessageVisible.value = !window.matchMedia('(max-width: 767px)').matches
  initialLoad.value = false
}

const getToken = () => {
  // Get AccountNo by using token from url params
  if (tokenId.value) {
    xsrfToken(xsrfTokenStore).then(() => {
      instance()
        .get(`/Patient/GetPatientPortalToken/${tokenId.value}`)
        .then((response: AxiosResponse) => {
          if (response.data.error == undefined) {
            // assign json data to Vue tokenInfo obj
            Object.assign(tokenResponse, response.data)

            // populate accountNo
            form.accountNo = tokenResponse.accountNo

            // DOB and DOS must be presented
            if (tokenResponse.chosenFactors.length < 2) showLoginPanel.value = false
          } else {
            modalToggle.errorModal = true
            responseResult.value = response.data.error
          }
        })
    })
  }
}

const getPatientCount = () => {
  instance()
    .get('/Patient/GetPatientCount')
    .then((response: AxiosResponse) => {
      if (response.data.error == undefined) {
        patientCount.value = response.data.patientCount
      }
    })
    .catch(() => {
      patientCount.value = 281800 // failback number
    })
}

const onSubmit = async () => {
  // submit login api request
  await login(appInfoStore, form).then((error) => {
    if (error == undefined) {
      router.push({ name: 'PatientForm' })
    } else {
      modalToggle.errorModal = true
      responseResult.value = `Error: ${error}`
    }
  })
}

const loginWithoutAuthentication = () => {
  showSpinner.value = true
  form.loginWithoutAuthentication = true

  login(appInfoStore, form).then((error) => {
    if (error == undefined) {
      router.push({ name: 'PatientForm' })
    } else {
      modalToggle.errorModal = true
      responseResult.value = `Error: ${error}`
    }
  })
}

/* ----- LifeCycle ----- */
onBeforeMount(async () => {
  // reset pinia data
  appInfoStore.resetAppInfo()

  // parse API token to accountNo
  if (tokenId.value == null) {
    xsrfToken(xsrfTokenStore)
  } else {
    showLoginSpinner.value = true
    getToken()
    showLoginSpinner.value = false
  }

  // set PortalSourceType
  const pilPortalSourceTypeID = 3

  // if it is from portal link
  if (route.path.includes('/Home/PatientLogin')) {
    appInfoStore.$patch({
      appInfo: { ...appInfoStore.appInfo, portalSourceTypeID: pilPortalSourceTypeID }
    })
  } else {
    // record querystring
    const urlParams = new URLSearchParams(window.location.search)
    const src = urlParams.get('src')
    const portalSourceTypeID = Number(src)

    // check if sourceID is a integer
    if (portalSourceTypeID != 0 && Number.isInteger(portalSourceTypeID)) {
      appInfoStore.$patch({
        appInfo: { ...appInfoStore.appInfo, portalSourceTypeID: portalSourceTypeID }
      })
    }
  }
})

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })

  // invoke resize function once
  onResize()

  getPatientCount()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<template>
  <div class="row justify-content-center">
    <!-- Need Help Modal -->
    <ModalComponent v-model="modalToggle.needHelpModal">
      <template #header> </template>
      <p>You can find your account number located on the patient information letter you may have received:</p>
      <img class="img-fluid" src="@/assets/images/PILSampleInfo.jpg" style="width: 100%" />
      <p class="text-center">Or</p>
      <p>
        Please call the Paratus customer service line at
        <a href="tel:(855) 995-3345" target="hidden-iframe">(855) 995-3345</a>, Monday-Friday, 8 a.m. to 4:30 p.m. Pacific Time, or email
        <a href="mailto:info@paratusllc.org" target="hidden-iframe">info@paratusllc.org</a> for assistance.
      </p>
    </ModalComponent>

    <!-- Page Load Modal -->
    <!-- <ModalComponent v-model="modalToggle.loginModal">
      <template #header>
        <div class="text-start">
          <img class="img-fluid" src="@/assets/images/ParatusLogo_56x50.png" />
          <a href="https://www.paratusllc.org" style="color: #000; text-decoration: none; line-height: 50px; font-size: 35px; margin-left: 0.25rem">paratus</a>
        </div>
      </template>
      <WelcomeMessage
        :cls="['text-center']"
        :patientName="tokenResponse.firstName"
        :hasToken="tokenId != undefined"
        :showLoginPanel="showLoginPanel"
        :isMobile="true"
      ></WelcomeMessage>
      <template #footer>
        <div class="text-end">
          <button class="btn btn-primary" @click="modalToggle.loginModal = false">Continue</button>
        </div>
      </template>
    </ModalComponent> -->

    <!-- Erro Modal -->
    <ModalComponent v-model="modalToggle.errorModal">
      <div class="alert alert-danger" role="alert">
        {{ responseResult }}
      </div>
    </ModalComponent>

    <!-- Main Card Body  -->
    <div class="card border-light text-center col-md-10">
      <div class="card-body">
        <!-- <WelcomeMessage
          :cls="['col-md-9', 'mx-auto']"
          :visible="welcomeMessageVisible"
          :patientName="tokenResponse.firstName"
          :hasToken="tokenId != undefined"
          :showLoginPanel="showLoginPanel"
        ></WelcomeMessage> -->
        <!-- Account and DOB input -->
        <div class="form-group row mt-3 justify-content-center">
          <div class="col-md-4 fw-bolder fst-italic d-flex flex-column justify-content-center" style="font-size: 1.05rem">
            <div class="shadow p-3 mb-2 bg-body rounded">
              Paratus helped my dad get his hospital bill paid while we all focused on him getting better.
              <div>Tom, Brea, CA</div>
            </div>
            <div class="shadow p-3 mb-2 bg-body rounded">
              I had no idea that Medicaid requires that auto insurance pay my medical bill.
              <div>Maria, Eugene, OR</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="shadow p-3 mb-2 bg-body rounded calc-height">
              <Form @submit="onSubmit" v-slot="{ errors, isSubmitting }">
                <!-- Add Spinner -->
                <LoadingSpinner :visible="isSubmitting"></LoadingSpinner>
                <div v-if="showLoginPanel">
                  <h5 class="mb-3 text-primary text-start fw-bolder">Log In</h5>
                  <div class="mb-3">
                    <div class="text-start">
                      <label for="accountNo" class="form-label text-truncate text-md-right font-weight-bolder text-secondary">Account# or Reference#</label>
                    </div>
                    <Field
                      name="Account No."
                      :rules="{ required: true, min: 3, max: 50 }"
                      v-model="form.accountNo"
                      v-slot="{ field, errors }"
                      v-if="tokenResponse.accountNo.length == 0"
                    >
                      <input id="accountNo" type="text" v-mask="alphaNumTokens" class="form-control" :class="errors.length ? 'border-danger' : ''" v-bind="field" />
                    </Field>
                    <input id="maskedAccountNo" type="text" disabled title="Masked Info." v-if="tokenResponse.accountNo.length > 0" class="form-control" :value="maskedAccountNo" />
                  </div>
                  <div class="mb-3">
                    <div class="text-start">
                      <label for="dob" class="form-label text-truncate text-md-right font-weight-bolder text-secondary">Date of Birth</label>
                    </div>
                    <Field name="DOB" v-model="form.dob" :rules="{ required: true, after: ['01/01/1900', true], before: ['today', true] }" v-slot="{ field, errors }">
                      <input
                        id="dob"
                        v-mask="'##/##/####'"
                        placeholder="MM/DD/YYYY"
                        type="tel"
                        class="form-control"
                        :class="errors.length ? 'border-danger' : ''"
                        autocomplete="disabled"
                        @focus=";($event.target as HTMLInputElement).select()"
                        v-bind="field"
                      />
                    </Field>
                  </div>

                  <div class="mb-3">
                    <input id="SubmitPatientLogin" type="submit" value="Log In" class="btn btn-primary btn-large w-100" :disabled="isSubmitting" />
                  </div>
                </div>

                <div class="" v-if="!showLoginPanel">
                  <div class="col-md-4"></div>
                  <div class="col-md-6 col-xl-4">
                    <input
                      id="SubmitPatientLoginNoAuth"
                      type="button"
                      @click="loginWithoutAuthentication"
                      :value="'Continue'"
                      class="btn btn-info btn-large w-100"
                      :disabled="isSubmitting"
                    />
                  </div>
                </div>

                <!-- Validation Summary -->
                <div class="row mb-3">
                  <div class="col-md-4"></div>
                  <div class="col-md-4 col-xl-4">
                    <div v-if="Object.keys(errors).length">
                      <div v-for="(message, field) in errors" :key="field">
                        <div class="alert alert-danger p-1 my-2" role="alert">
                          {{ message }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2 mb-4">
                  <a href="javascript:;" @click="modalToggle.needHelpModal = true" class="text-decoration-none text-info"><strong>HELP</strong></a>
                  <div class="mt-3">Still have questions?</div>
                  <div class="mt-2">Please call us at</div>
                  <div>
                    <a href="tel:(855) 995-3345" target="hidden-iframe">(855) 995-3345</a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <div class="col-md-4">
            <div class="shadow p-3 mb-2 bg-body rounded calc-height" style="font-size: 1.1rem">
              We partner with your hospital and have helped thousands of patients navigate the complex process of paying their medical bills after an accident.<br /><br />
              Let us help you. <span class="fw-bolder">Give us 5 minutes and we'll save you hours of headaches.</span>
              <div class="mt-4">
                <div style="font-size: 1.1rem">Patients helped so far:</div>
                <span class="fw-bolder pat-count" :style="{ '--num': patientCount }" style="font-size: 1.2rem"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-1">
        Paratus partners with your hospital to help ensure your bill is properly paid by the party responsible for the accident. One of the biggest challenges hospitals and
        patients face is figuring out proper payment channels for accident related hospital visits. Few people realize that Medicare, Medicaid, and most commercial medical insurers
        are payers of last resort for these types of bills.
      </div>
    </div>
  </div>
</template>

<style scoped>
@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

@property --v0000 {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
@property --v000 {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
@property --v00 {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
@property --v0 {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.pat-count {
  transition: --num 2s;
  /* To enforce division to round down, minus 50% of last digit 
     Enforce calcuation on v000, v00, v0 to keep leading zero
  */
  --v0000: max((var(--num) - 500) / 1000, 0);
  --v000: max((var(--num) - var(--v0000) * 1000 - 50) / 100, 0);
  --v00: max((var(--num) - var(--v0000) * 1000 - var(--v000) * 100 - 5) / 10, 0);
  --v0: max((var(--num) - var(--v0000) * 1000 - var(--v000) * 100 - var(--v00) * 10 - 0.5), 0);
  counter-reset: v0000 var(--v0000) v000 var(--v000) v00 var(--v00) v0 var(--v0);
  font: 800 40px system-ui;
}

.pat-count::after {
  content: counter(v0000) ',' counter(v000) counter(v00) counter(v0);
}

.calc-height {
  height: calc(100% - 1em);
}
</style>
