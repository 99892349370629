<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import ModalComponent from '@/components/UI/ModalComponent.vue'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  id: { type: String, default: 'contactAddressValidate' },
  validationResponse: { type: Object, default: () => ({}) },
  // addressResponse: { type: Object, default: () => ({}) },
  selectedParty: { type: Object, default: () => ({}) }
})
const emit = defineEmits<{
  (e: 'update:modelValue'): void
  (e: 'submit', addressResponsePicked: string): void
}>()

const addressResponsePicked = ref('Corrected')
const disableProcessButton = ref(false)
const showModal = ref(false)

const addressResponse = computed(() => props.validationResponse.addressResponse)

const checkDisableProcessButton = () => {
  if (addressResponse.value.taskNoteCorrected && addressResponse.value.taskNoteOriginal) {
    if (addressResponsePicked.value === 'Original') {
      disableProcessButton.value = false
    }
  } else if (addressResponse.value.taskNoteCorrected && addressResponsePicked.value === 'Original') {
    disableProcessButton.value = false
  }
}

watch(
  () => props.validationResponse,
  (newValue) => {
    if (newValue && addressResponse.value.taskNoteOriginal) {
      disableProcessButton.value = false
      addressResponsePicked.value = 'Corrected'
      if (!addressResponse.value.taskNoteCorrected && addressResponse.value.taskNoteOriginal) {
        disableProcessButton.value = true
      }
      showModal.value = true
    }
  }
)

watch(
  () => props.validationResponse.addressResponse,
  (newValue) => {
    if (Object.keys(newValue).length == 0) showModal.value = false
  }
)
</script>

<template>
  <div>
    <ModalComponent v-model="showModal" @submit="emit('submit', addressResponsePicked)">
      <template #header>
        <span class="bi bi-exclamation-triangle text-warning" aria-hidden="true"></span>
        <span class="text-primary px-1">Correct Address?</span>
      </template>
      <div :class="{ 'col-md-6': addressResponse.taskNoteOriginal }" v-if="addressResponse.taskNoteOriginal" style="margin-bottom: 10px; width: 100%">
        <div style="background-color: #eeeeee; padding: 5px">
          <h4 :class="{ 'text-danger': !addressResponse.taskNoteCorrected }">Insurance {{ addressResponse.taskNoteDesc }}</h4>
          <p v-if="!addressResponse.taskNoteCorrected" class="text-danger">If this is not the inteneded address please cancel and update the address</p>
        </div>
        <div
          class="address-box"
          :class="{ 'is-active': addressResponsePicked === 'Original' }"
          @click="
            () => {
              addressResponsePicked = 'Original'
              checkDisableProcessButton()
            }
          "
        >
          <h4 style="margin-bottom: 2px" class="text-primary">Original Address:</h4>
          <p style="margin: 0px">{{ selectedParty.company }}</p>
          <input type="radio" value="Original" v-model="addressResponsePicked" />&nbsp;
          <label>{{ addressResponse.taskNoteOriginal }}</label>
        </div>
        <span v-if="addressResponse.taskNoteCorrected">
          <div class="address-box" :class="{ 'is-active': addressResponsePicked === 'Corrected' }" @click="addressResponsePicked = 'Corrected'">
            <h4 style="margin-bottom: 2px" class="text-success">Corrected Address:</h4>
            <p style="margin: 0px">{{ selectedParty.company }}</p>
            <input type="radio" value="Corrected" v-model="addressResponsePicked" />&nbsp;
            <label>{{ addressResponse.taskNoteCorrected }}</label>
          </div>
        </span>
      </div>
      <template #footer></template>
    </ModalComponent>
  </div>
</template>

<style scoped>
.is-active {
  background-color: #fff59d;
}

.address-box {
  padding: 5px;
  font-weight: bold;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}
</style>
