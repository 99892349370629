<script setup lang="ts">
import { useSlots } from 'vue'
const slots = useSlots()

defineProps({
  modelValue: { type: Boolean, default: false },
  submitButtonDisabled: { type: Boolean, default: false },
  showSubmitButton: { type: Boolean, default: true },
  showCancelButton: { type: Boolean, default: true },
  modalBodyClass: { type: Array<String>, default: () => [] },
  submitButtonClass: { type: Array<String>, default: () => ['btn-primary'] },
  submitButtonName: { type: String, default: 'Submit' },
  cancelButtonName: { type: String, default: 'Cancel' }
})
const emit = defineEmits(['update:modelValue', 'submit'])

const submitHandler = () => {
  emit('update:modelValue', false)
  emit('submit')
}
</script>

<template>
  <Teleport to="#modal-container" v-if="modelValue">
    <div class="ez-modal-container" @click="emit('update:modelValue', false)">
      <div class="ez-modal-body m-2" @click.stop>
        <div class="card-body d-flex flex-column flex-wrap" :class="modalBodyClass">
          <header style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); position: relative; min-height: 3rem" v-if="slots.header">
            <div style="position: absolute; right: 1rem; top: 0.125rem">
              <button type="button" class="close" aria-label="Close" @click="emit('update:modelValue', false)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h4>
              <slot name="header"></slot>
            </h4>
          </header>
          <main style="text-align: center">
            <slot></slot>
          </main>
          <footer style="border-top: 1px solid rgba(0, 0, 0, 0.2); display: flex; justify-content: space-around" v-if="slots.footer">
            <slot name="footer">
              <button type="submit" v-if="showSubmitButton" class="btn" :class="submitButtonClass" :disabled="submitButtonDisabled" @click="submitHandler">
                {{ submitButtonName }}
              </button>
              <button type="button" class="btn btn-secondary" v-if="showCancelButton" @click="emit('update:modelValue', false)">{{ cancelButtonName }}</button>
            </slot>
          </footer>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.ez-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.ez-modal-body {
  background-color: white;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 0.5rem;
  animation: slide-down 300ms ease-out forwards;
}

.card-body > * {
  padding: 1rem;
}

@media (min-width: 768px) {
  .ez-modal-body {
    width: 40rem;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 24pt;
  color: #666;
}
</style>
