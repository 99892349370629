import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useXsrfTokenStore = defineStore(
  'xsrfToken',
  () => {
    const token = ref('')

    const setTokenInfo = (data: any) => {
      token.value = data
    }

    return { token, setTokenInfo }
  },
  { persist: true }
)
