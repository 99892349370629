import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import './assets/styles/Site.css'
import { defineRule } from 'vee-validate'
import { required, length, integer, email, min, max, alpha_num, digits } from '@vee-validate/rules'
import dayjs from 'dayjs'
import VueTheMask from 'vue-the-mask'
import VueGtag from 'vue-gtag'

// Define vee-validate rules globally
defineRule('required', required)
defineRule('length', length)
defineRule('integer', integer)
defineRule('email', email)
defineRule('min', min)
defineRule('max', max)
defineRule('alpha_num', alpha_num)
defineRule('digits', digits)

// Custom vee-validate rules
const dateFormat = 'MM/DD/YYYY'
defineRule('before', (value: string, [limit, included]: [string, boolean], { name }) => {
  // The field is empty so it should pass
  if (!value || !value.length) return true

  if (value.length != 10) return `${name} is an invalid date`

  // apply strict dateformat check
  if (!dayjs(value, dateFormat, true).isValid()) return `${name} is an invalid date`

  const value_date = dayjs(value, dateFormat)
  const limit_date = limit == 'today' ? dayjs() : dayjs(limit, dateFormat)
  const limit_date_formatted = limit_date.format(dateFormat)

  if ((included && value_date.isAfter(limit_date)) || (!included && (value_date.isSame(limit_date) || value_date.isAfter(limit_date))))
    return `${name} must be ${included ? 'on or' : ''} before ${limit_date_formatted}`

  return true
})

defineRule('after', (value: string, [limit, included]: [string, boolean], { name }) => {
  // The field is empty so it should pass
  if (!value || !value.length) return true

  if (value.length != 10) return `${name} is an invalid date`

  // apply strict dateformat check
  if (!dayjs(value, dateFormat, true).isValid()) return `${name} is an invalid date`

  const value_date = dayjs(value, dateFormat)
  const limit_date = limit == 'today' ? dayjs() : dayjs(limit, dateFormat)
  const limit_date_formatted = limit_date.format(dateFormat)

  if ((included && value_date.isBefore(limit_date)) || (!included && (value_date.isSame(limit_date) || value_date.isBefore(limit_date))))
    return `${name} must be ${included ? 'on or' : ''} after ${limit_date_formatted}`

  return true
})

// Setup google analytics
const gtag = {
  config: { id: 'G-GNNN44D6X4' },
  enabled: window.location.origin == 'https://portal.paratusllc.org',
  pageTrackerSkipSamePath: false //Use this for tracking query parameter changes such as when clicking header arrows to navigate back/forth through cases in the manage case page.
}

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

app.use(VueGtag, gtag)

// @ts-expect-error
app.use(VueTheMask)
app.use(router)

app.mount('#app')
