<script setup lang="ts">
import { computed, watch } from 'vue'
import ModalComponent from '@/components/UI/ModalComponent.vue'
import { toPhone } from '@/helpers/helpers'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  id: { type: String, default: 'contactPhoneValidate' },
  confirmPhoneValidation: { type: Boolean, default: false },
  validationResponse: { type: Object, default: () => ({}) },
  selectedParty: { type: Object, default: () => ({}) }
})

const emit = defineEmits(['update:modelValue', 'submit'])

const showModal = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  }
})

watch(
  () => props.validationResponse,
  (newValue) => {
    if (newValue.phoneResponse && !props.confirmPhoneValidation) {
      showModal.value = true
    }
  }
)
</script>

<template>
  <div>
    <ModalComponent v-model="showModal" :modalBodyClass="['text-center']" @submit="emit('submit')">
      <template #header>
        <span class="bi bi-exclamation-triangle text-warning" aria-hidden="true"></span>
        <span class="text-primary px-1">Invalid Phone Number</span>
      </template>
      <div>
        <div>
          <h6>
            <strong>{{ props.validationResponse?.phoneResponse }}</strong>
          </h6>
        </div>
        <div>
          <span v-if="!props.validationResponse.phoneCheck">
            <h4 style="margin-bottom: 2px" class="text-danger">Invalid Phone:</h4>
            <p style="margin: 0px">{{ toPhone(props.selectedParty.phone) }}</p>
          </span>
          <span v-if="!props.validationResponse.phoneCheckMobile">
            <h4 style="margin-bottom: 2px" class="text-danger">Invalid Mobile Phone:</h4>
            <p style="margin: 0px">{{ toPhone(props.selectedParty.mobile) }}</p>
          </span>
        </div>
      </div>
      <template #footer></template>
    </ModalComponent>
  </div>
</template>
