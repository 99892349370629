import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { PatientLoginInfo } from '@/modules/loginPanel'

// setup store API
export const useAppInfoStore = defineStore(
  'appInfo',
  () => {
    const getDefaultAppInfo = () =>
      ({
        caseId: 0,
        accountNo: '',
        dob: new Date(),
        dos: new Date(),
        portalSourceTypeID: 0,
        loginWithoutAuthentication: false
      } as PatientLoginInfo)

    // initial state
    const appInfo = ref(getDefaultAppInfo())

    const resetAppInfo = () => {
      appInfo.value = getDefaultAppInfo()
    }

    return { appInfo, resetAppInfo }
  },
  { persist: true }
)
