import { instance } from '@/helpers/interceptors'
import type { AxiosResponse } from 'axios'

export const xsrfToken = async (store: any) => {
  await instance()
    .get('/App/AddXsrfToken')
    .then((response: AxiosResponse) => {
      if (response) {
        store.setTokenInfo(response.data)
      }
    })
}
